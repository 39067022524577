
import { defineComponent, ref, onMounted, onBeforeUnmount, watch } from "vue";
import { useI18n } from "vue-i18n";
import discover from "@/assets/imgs/discover.webp";
import discoverb from "@/assets/imgs/discoverb.webp";

export default defineComponent({
  name: "Tabbar",
  setup(props, { emit }) {
    const { t } = useI18n();
    const tabArray = ref([
      {
        name: "Home",
        path: "/",
        title: t("Home"),
      },
      {
        name: "Explore",
        path: "/explore",
        title: t("Explore"),
      },
      {
        name: "Rankings",
        path: "/rankings",
        title: t("Rankings"),
      },
      {
        name: "Discover",
        path: "/discover",
        title: t("Discover"),
      },
      {
        name: "Stats",
        path: "/stats",
        title: t("Stats"),
      },
    ]);
    return {
      tabArray,
      discover,
      discoverb,
    };
  },
});
