import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-679 min-w-screen w-screen min-h-screen" }
const _hoisted_2 = { class: "min-h-screen max-w-1800 mx-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Navagation = _resolveComponent("Navagation")!
  const _component_Header = _resolveComponent("Header")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Tabbar = _resolveComponent("Tabbar")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Navagation, { class: "pad:hidden pc:hidden" }),
    _createVNode(_component_Header, { class: "h5:hidden" }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_router_view)
    ]),
    _createVNode(_component_Tabbar, { class: "pad:hidden pc:hidden" }),
    _createVNode(_component_Footer, { class: "h5:hidden" })
  ]))
}