
import { defineComponent, ref, onMounted, onBeforeUnmount, watch } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "Footer",
  setup(props) {
    const { t } = useI18n();

    return {};
  },
});
