
export interface Tab {
  name: string;
  path: string;
  title: string;
}
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import Tabbar from "@/components/mobile/Tabbar.vue";
import Navagation from "@/components/mobile/Navagation.vue";
import Header from "./components/commons/Header.vue";
import Footer from "@/components/commons/Footer.vue";
import { screenWidth } from "./utils/platform";
import store from "./store";
export default defineComponent({
  name: "App",
  components: {
    Tabbar,
    Navagation,
    Header,
    Footer,
  },
  setup() {
    const Width = screenWidth();
    console.log("Width", Width);
    store.dispatch("changeWidth", Width);
  },
});
