/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
export const platform = () => {
  const screenWidth = window.innerWidth || document.documentElement.clientWidth;
  if (screenWidth <= 680) {
    return "h5";
  } else if (screenWidth > 680 && screenWidth <= 1250) {
    return "pad";
  } else {
    return "pc";
  }
};

export const screenWidth = () => {
  return window?.innerWidth || document?.documentElement?.clientWidth;
};
