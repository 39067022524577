import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "h-15" }
const _hoisted_2 = { class: "bg-dark-414 text-white-40 flex h-15 fixed z-40 left-0 bottom-0 w-full" }
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabArray, (item, index) => {
        return (_openBlock(), _createBlock(_component_router_link, {
          key: index,
          class: "flex flex-col w-20% items-center justify-center",
          to: item.path
        }, {
          default: _withCtx(() => [
            (item.name === 'Discover')
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  width: "22",
                  height: "22",
                  class: _normalizeClass({'opacity-40': _ctx.$route.name !== item.name}),
                  src: _ctx.$route.name === item.name ? _ctx.discoverb : _ctx.discover,
                  alt: "discover"
                }, null, 10, _hoisted_3))
              : (_openBlock(), _createElementBlock("i", {
                  key: 1,
                  class: _normalizeClass(["font-normal", `icon-${item.name} ${
            _ctx.$route.name === item.name ? 'text-blue-0ee' : ''
          }`]),
                  style: {"font-size":"22px","line-height":"22px"}
                }, null, 2)),
            _createElementVNode("div", {
              class: _normalizeClass([{ 'text-blue-0ee': _ctx.$route.name === item.name }, "text-12 mt-1"])
            }, _toDisplayString(item.title), 3)
          ]),
          _: 2
        }, 1032, ["to"]))
      }), 128))
    ])
  ]))
}