/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
import img from "@/assets/imgs/defaultImage.webp";
import img1 from "@/assets/imgs/defaultImage1.webp";
import img2 from "@/assets/imgs/defaultImage2.webp";
export default {
  install(app: any): void {
    const observe = (el, binding) => {
      const observer = new IntersectionObserver(([{ isIntersecting }]) => {
        if (isIntersecting) {
          observer.unobserve(el)
          el.src = binding.value;
          el.onerror = () => {
            el.src = binding?.arg == '1' ? img1 : binding?.arg == '2' ? img2 : img;
          }
        }
      }, {
        threshold: 0
      })
      observer.observe(el)
    };
    app.directive('lazyload', {
      beforeUpdate(el, binding) {
        observe(el, binding);
      },
      mounted(el, binding) {
        el.src = binding?.arg == '1' ? img1 : binding?.arg == '2' ? img2 : img;
        observe(el, binding);
      },
    })
  }
}
