/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { i18n } from "./locales";
import smoothscroll from "smoothscroll-polyfill";
import lazyImg from "@/directives/imgLazyLoad";
import "./styles/tailwind.css";
import "./styles/base.scss";
import "./assets/icon-font/icon.css";
import "./assets/fonts.css";
import VueGtag from "vue-gtag-next";
import { platform, screenWidth } from "./utils/platform";

// kick off the polyfill!
smoothscroll.polyfill();
window.addEventListener("resize", () => {
  const plat = platform();
  const Width = screenWidth();
  store.dispatch("changePlatform", plat);
  store.dispatch("changeWidth", Width);
});

createApp(App)
  .use(store)
  .use(router)
  .use(i18n)
  .use(lazyImg)
  .use(VueGtag, {
    property: {
      id: "G-DNMBC8VC9J",
    },
  })
  .mount("#app");
