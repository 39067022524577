/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
  },
  {
    path: "/explore",
    name: "Explore",
    component: () => import("@/views/Explore.vue"),
  },
  {
    path: "/rankings",
    name: "Rankings",
    component: () => import("@/views/Rankings.vue"),
  },
  {
    path: "/discover",
    name: "Discover",
    component: () => import("@/views/Discover.vue"),
  },
  {
    path: "/stats",
    name: "Stats",
    component: () => import("@/views/Stats.vue"),
  },
  {
    path: "/submit-dapp",
    name: "SubmitDapp",
    component: () => import("@/views/SubmitDapp.vue"),
  },
  {
    path: "/collection/:id",
    name: "Collection",
    component: () => import("@/views/Collection.vue"),
  },
  {
    path: "/detail/:id",
    name: "Detail",
    component: () => import("@/views/detail/Detail.vue"),
  },
  {
    path: "/:catchAll(.*)*",
    name: "NotFound",
    redirect: "/"
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
