
import { defineComponent, ref, onMounted, onBeforeUnmount, watch } from "vue";

export default defineComponent({
  name: "Navagation",
  setup(props) {
    const handleSearch = () => {
      console.log("search");
    };
    const handleSubmitDapp = () => {
      console.log("submitDapp");
    };
    return {
      handleSearch,
      handleSubmitDapp,
    };
  },
});
