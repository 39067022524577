/*
 * Copyright © 2022 Opera Norway AS. All rights reserved.
 *
 * This file is an original work developed by Opera.
 */
import { createI18n } from "vue-i18n";
import en from "./languages/en.json";
const langArray = ["en"];
const lang = "en";

const i18n = createI18n({
  legacy: false,
  locale: lang,
  messages: {
    en,
  }
});

// export default i18n
export {
  i18n,
  langArray,
  lang
} 
