import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withKeys as _withKeys, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["value", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "flex items-center pad:py-3 px-4 py-4 border",
    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.handleInput && _ctx.handleInput(...args)))
  }, [
    _createElementVNode("i", {
      class: _normalizeClass(["flex-grow-0 flex-shrink-0 icon-Search mr-2.5", `${_ctx.focus?'':_ctx.iconColor} ${_ctx.focus ? 'text-white-100':''} `]),
      style: {"font-size":"18px","line-height":"18px"}
    }, null, 2),
    _createElementVNode("input", {
      ref: "ipt",
      class: "h-5 w-full flex-grow flex-shrink border-none outline-none bg-black-0",
      type: "text",
      value: _ctx.search,
      placeholder: _ctx.placeholder,
      onInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:search', $event.target.value))),
      onKeyup: _cache[1] || (_cache[1] = _withKeys(($event: any) => (_ctx.$emit('finish')), ["enter"])),
      onFocus: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:focus', true))),
      onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('update:focus', false)))
    }, null, 40, _hoisted_1),
    (_ctx.showClose)
      ? (_openBlock(), _createElementBlock("i", {
          key: 0,
          class: _normalizeClass(["flex-grow-0 flex-shrink-0 font-normal icon-Close ml-2.5 cursor-pointer", `${_ctx.iconColor}`]),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('update:search', ''))),
          style: {"font-size":"20px","line-height":"20px"}
        }, null, 2))
      : _createCommentVNode("", true)
  ]))
}