import { createStore } from "vuex";
import { platform } from "../utils/platform";

export default createStore({
  state: {
    platform: platform(),
    Width: 414,
  },
  getters: {},
  mutations: {
    changePlatform(state, platform) {
      state.platform = platform;
    },
    changeWidth(state, Width) {
      state.Width = Width;
    },
  },
  actions: {
    changePlatform({ commit }, platform: string) {
      commit("changePlatform", platform);
    },
    changeWidth({ commit }, Width: Number) {
      commit("changeWidth", Width);
    },
  },
  modules: {},
});
