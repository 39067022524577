
import { defineComponent, ref, onMounted, onBeforeUnmount, watch } from "vue";
import Search from "@/components/commons/Search.vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import discover from "@/assets/imgs/discover.webp";
import discoverb from "@/assets/imgs/discoverb.webp";

export default defineComponent({
  name: "Header",
  components: {
    Search,
  },
  setup(props) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const hadFocus = ref(false);
    const search = ref("");
    const tabArray = ref([
      {
        name: "Home",
        path: "/",
        title: t("Home"),
      },
      {
        name: "Explore",
        path: "/explore",
        title: t("Explore"),
      },
      {
        name: "Rankings",
        path: "/rankings",
        title: t("Rankings"),
      },
      {
        name: "Discover",
        path: "/discover",
        title: t("Discover"),
      },
      {
        name: "Stats",
        path: "/stats",
        title: t("Stats"),
      },
    ]);

    const handleSearch = () => {
      router.push({ path: "/explore", query: { search: search.value } });
    };

    return {
      hadFocus,
      search,
      tabArray,
      handleSearch,
      discover,
      discoverb,
    };
  },
});
